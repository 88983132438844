import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import numeral from "numeral";
import locales from "numeral/locales";
import withTranslator from "./hocs/withTranslator";

numeral.locale("pt-br");

const styles = (theme) => ({
  progressBarContainer: {
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: "20px",
    overflow: "hidden",
    position: "relative",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    height: "40px",
    borderWidth: "3px",
    borderStyle: "solid",
    // borderColor: theme.palette.secondary.light,
    borderColor: "rgba(255, 255, 255, .5)",
  },
  progressFill: {
    backgroundColor: theme.palette.secondary.light,
    height: "100%",
    borderRadius: "20px 0 0 20px",
    transition: "width 2s ease-out",
  },
  progressText: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
  },
});

const ProgressTracker = ({ classes, current, i18n }) => {
  const destination = 100;
  const progress = Math.min((current / destination) * 100, 100);

  return (
    <Box sx={{ width: "100%" }}>
      <Box className={classes.progressBarContainer}>
        <Box
          className={classes.progressFill}
          style={{ width: `${progress}%` }}
        />
        <Typography className={classes.progressText} variant="body1">
          <b>
            {numeral(current / destination).format("0%")}{" "}
            {i18n.t("filled", "filled")}
          </b>
        </Typography>
      </Box>
    </Box>
  );
};

export default withTranslator(withStyles(styles)(ProgressTracker));
