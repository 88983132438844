import React, { Component } from "react";
import { graphql } from "react-apollo";
import { Link } from "react-router-dom";

import numeral from "numeral";
import locales from "numeral/locales";

import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Grid,
  Hidden,
  Paper,
  Select,
  MenuItem,
  FormHelperText,
  Slider,
  Typography,
  withMobileDialog,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

import { loader } from "graphql.macro";

import withTranslator from "./hocs/withTranslator";
import withSnackbar from "./hocs/withSnackbar";
import { constants } from "../global";

const sendContactUsEmailMutation = loader(
  "../mutations/SendContactUsEmail.graphql"
);

numeral.locale("pt-br");

const styles = (theme) => ({});
const CustomSlider = withStyles({
  thumb: {
    height: 26,
    width: 26,
    marginTop: -12,
    border: "solid 3px #fff",
  },
  rail: { height: "4px", borderRadius: "2px", backgroundColor: "#EEEFF3" },
  track: {
    height: "8px",
    borderRadius: "4px 0 0 4px",
    marginTop: -3,
    backgroundColor: "#3AB364",
    border: "none",
  },
  // root: {
  //   height: 8,
  // },
})(Slider);

class SavingsCalculatorDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactFormOpen: false,
      distributor: "cemig",
      phase: "phase3",
      monthlyCost: 500,
    };
    this.updateMonthlyCost = this.updateMonthlyCost.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  updateMonthlyCost(event, val) {
    this.setState({ monthlyCost: val });
  }

  getSavings() {
    const { monthlyCost, phase, distributor } = this.state;
    let multiplier = 1;
    let discount;
    if (distributor === "cemig") {
      discount = constants.discountRate;
      switch (phase) {
        case "phase1":
          multiplier = 0.89;
          break;
        case "phase2":
          multiplier = 0.93;
          break;
        case "phase3":
          multiplier = 0.99;
          break;

        default:
          break;
      }
    } else {
      // discount = 0.12;
      discount = constants.discountRate;
      switch (phase) {
        case "phase1":
          multiplier = 0.9;
          break;
        case "phase2":
          multiplier = 0.95;
          break;
        case "phase3":
          multiplier = 1.01;
          break;

        default:
          break;
      }
    }

    return monthlyCost * 12 * discount * multiplier;
  }

  handleSelectChange(event, val) {
    this.setState({ [event.target.name]: val.props.value });
  }

  render() {
    const { open, onClose, fullScreen, i18n } = this.props;
    const { monthlyCost, phase, distributor } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        fullScreen={fullScreen}
        open={!!open}
        onClose={onClose}
      >
        <DialogContent>
          <Grid container justifyContent="space-between">
            <Hidden mdDown>
              <Grid item xs="auto">
                <div style={{ position: "relative", width: "530px" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      marginRight: "-1rem",
                      width: "300px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      style={{ marginTop: "-1rem", overflow: "hidden" }}
                      src="https://res.cloudinary.com/dwelloptimal/image/upload/v1654612402/energea/br-images/Ellipse_21.webp"
                      alt="Ellipse shape"
                    />
                    <Typography
                      variant="h5"
                      color="primary"
                      style={{
                        position: "absolute",
                        textAlign: "center",
                        fontWeight: "400 !important",
                        top: "30px",
                        right: "0",
                        marginRight: "1rem",
                      }}
                    >
                      Quanto posso economizar?
                    </Typography>
                  </div>
                </div>
                <Paper
                  elevation={0}
                  style={{
                    marginRight: "1rem",
                    height: "100%",
                    borderRadius: "20px",
                    // overflow: "visible",
                    minHeight: "600px",
                    background:
                      "linear-gradient(160.49deg, #00B775 0.66%, rgba(0, 183, 117, 0) 90.36%)",
                  }}
                >
                  <img
                    style={{ position: "absolute", left: 0, bottom: 0 }}
                    src="https://res.cloudinary.com/dwelloptimal/image/upload/v1654618147/energea/br-images/pessoa_1.webp"
                    alt="Person thinking"
                  />
                </Paper>
              </Grid>
            </Hidden>
            <Grid item lg={6} xs={12}>
              <Grid
                container
                justifyContent="flex-end"
                style={{ width: "100%" }}
              >
                <Grid item>
                  <IconButton aria-label="close" onClick={onClose}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography
                gutterBottom
                color="primary"
                variant="h2"
                style={{ marginBottom: "1rem", textAlign: "center" }}
              >
                {i18n.t("simulateSavings", "Save Money")}
              </Typography>
              <Grid container justifyContent="center" spacing={3}>
                <Grid container justifyContent="center" item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      variant="body1"
                      style={{ textAlign: "center" }}
                    >
                      Quanto você gasta por mês
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        margin: "1rem 0rem",
                      }}
                    >
                      {numeral(monthlyCost).format("$0")}
                    </Typography>
                    <Grid
                      container
                      justifyContent="center"
                      style={{ width: "100%" }}
                    >
                      <Grid item>
                        <CustomSlider
                          aria-label="Choose monthly utility cost"
                          style={{
                            marginBottom: "1rem",
                            width: "24rem",
                            margin: "auto",
                          }}
                          min={300}
                          max={3000}
                          value={monthlyCost}
                          onChange={this.updateMonthlyCost}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid style={{ paddingRight: ".5rem" }} item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body2"
                          color="primary"
                        >
                          Conexão
                        </Typography>
                        <Select
                          variant="outlined"
                          style={{ width: "100%" }}
                          value={phase}
                          name="phase"
                          onChange={this.handleSelectChange}
                        >
                          <MenuItem value="phase1">Monofásico</MenuItem>
                          <MenuItem value="phase2">Bifásico</MenuItem>
                          <MenuItem value="phase3">Trifásico</MenuItem>
                        </Select>
                      </Grid>
                      <Grid style={{ paddingLeft: ".5rem" }} item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body2"
                          color="primary"
                        >
                          Distribuidora
                        </Typography>
                        <Select
                          style={{ width: "100%" }}
                          value={distributor}
                          name="distributor"
                          variant="outlined"
                          onChange={this.handleSelectChange}
                        >
                          <MenuItem value="cemig">Cemig</MenuItem>
                          <MenuItem value="light">Light</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                    <Typography
                      gutterBottom
                      style={{
                        marginTop: "2rem",
                        textAlign: "center",
                        fontSize: "24px",
                      }}
                      variant="body1"
                      color="primary"
                    >
                      <b>Sua economia será de:</b>
                    </Typography>
                    <Grid
                      style={{ marginBottom: "2rem" }}
                      container
                      justifyContent="center"
                      spacing={fullScreen ? 1 : 5}
                    >
                      <Grid item>
                        <Typography
                          variant="body1"
                          color="primary"
                          style={{
                            minWidth: "120px",
                            fontSize: "24px",
                            textAlign: "center",
                          }}
                        >
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={1}
                          >
                            <Grid item>
                              {numeral(this.getSavings() / 12).format("$0")}{" "}
                            </Grid>
                            <Grid
                              item
                              style={{ fontSize: "14px", color: "#000" }}
                            >
                              {" "}
                              /Mês
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{
                          padding: 0,
                          width: 0,
                          margin: "1rem 0",
                          borderRight: "thin solid lightgrey",
                        }}
                      />
                      <Grid item>
                        <Typography
                          variant="body1"
                          color="secondary"
                          style={{
                            minWidth: "120px",
                            fontSize: "24px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                              {numeral(this.getSavings()).format("$0")}
                            </Grid>
                            <Grid
                              item
                              style={{ fontSize: "14px", color: "#000" }}
                            >
                              {" "}
                              /Ano
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                    </Grid>
                    <FormHelperText
                      style={{ width: "100%", textAlign: "center" }}
                      color="primary"
                    >
                      *Esta é apenas uma simulação. O seu desconto real pode
                      sofrer variações.
                    </FormHelperText>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    style={{
                      marginBottom: "1rem",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                    // onClick={() => {
                    //   // setRegisterOpen(true);
                    //   onClose();
                    // }}
                    color="secondary"
                    disabled={false}
                    variant="contained"
                    to="/register"
                    component={Link}
                  >
                    {i18n.t("saveMoney", "Save Money")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withSnackbar(
  graphql(sendContactUsEmailMutation)(
    withStyles(styles, { withTheme: true })(
      withMobileDialog()(withTranslator(SavingsCalculatorDialog))
    )
  )
);
